<template>
    <div>
        <template v-if="infos_monte.length == 0">
            <div class="box">
                <span class="text-center">
                    <i>{{ $t('global.no_result') }}</i>
                </span>
            </div>
        </template>
        <template v-else v-for="line in infos_monte">
            <div class="box infosmonte" :key="line.season.season_id">
                <!-- Si on a un foal -->
                <template v-if="line.foal">
                    <b>{{ $t('monte.horse.stallion', { season_label: line.season.season_label }) }}:</b> {{ line.stallion.horse_nom | multiCapitalize }}<br>
                    <b>{{ $t('monte.horse.term_date') }}:</b> {{ line.term_date }}<br>
                    <b>{{ $t('monte.horse.result') }}:</b> {{ line.foal.horse_nom | multiCapitalize }}, {{ line.foal.horse_robe | lowercase }}, {{ line.foal.horse_sexe }}, {{ line.foal.horse_datenaissance }}
                </template>
                <template v-else>
                    <b>{{ $t('monte.horse.stallion', { season_label: line.season.season_label }) }}:</b> {{ line.stallion.horse_nom | multiCapitalize }}<br>
                    <b>{{ $t('monte.horse.dds') }}:</b> {{ line.last_saillie ? line.last_saillie.actes_date : '' }}<br>
                    <b>{{ $t('monte.horse.dg') }}:</b> 
                        <span v-if="line.last_dg"> 
                            {{ line.last_dg.reponse.reponse_data }} {{ line.last_dg.actes_date }} 
                            ({{ line.saillie_delay }}{{ $t('monte.horse.days_diff') }})
                        </span>
                </template>
            </div>
        </template>
    </div>
</template>


<script type="text/javascript">
    import HorseMixin from "@/mixins/Horse.js"

	export default {
		name: "NoteFeed",
        mixins: [HorseMixin],
        props: {
            horse_id: { type: Number, default: 0 }
        },
        data() {
            return {
                infos_monte: [],
                loading: true
            }
        },
        created() {
            this.preinit_component()
        },
        methods: {
            preinit_component() {
                this.getMareMonteInfos(this.horse_id)
                    .then(res => this.infos_monte = res)
                    .finally(() => this.loading = false)
            }
        }
	}
</script>
